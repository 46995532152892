import { WorkCardData } from 'entities/work'

import loveCakeFirst from '../../shared/assets/love-cake-1.jpg'
import loveCakeSecond from '../../shared/assets/love-cake-2.jpg'
import loveCakeThird from '../../shared/assets/love-cake-3.jpg'
import loveCakeFourth from '../../shared/assets/love-cake-4.jpg'
import flowersCake from '../../shared/assets/flowers-cake.jpg'
import cupcakesCake from '../../shared/assets/cupcakes-cake.jpg'
import medovikCake from '../../shared/assets/medovik-cake.jpg'
import happyBirthdayCake from '../../shared/assets/happy-birthday-cake.jpg'
import happyBirthdayDigitCake from '../../shared/assets/happy-birthday-cake-digit.jpg'
import CupcakesRedVelvet from '../../shared/assets/cupcakes-red-velvet-1.jpg'
import CupcakesRedVelvetFirst from '../../shared/assets/cupcakes-red-velvet-2.jpg'
import CupcakesRedVelvetSecond from '../../shared/assets/cupcakes-red-velvet-3.jpg'
import CupcakesRedVelvetThird from '../../shared/assets/cupcakes-red-velvet-4.jpg'
import DecorCakeFirst from '../../shared/assets/decor-flowers-cake-1.jpg'
import DecorCakeSecond from '../../shared/assets/decor-flowers-cake-2.jpg'
import MinecraftCake from '../../shared/assets/minecraft-cake.jpg'
import GenderCakeFirst from '../../shared/assets/gender-cake-1.jpg'
import GenderCakeSecond from '../../shared/assets/gender-cake-2.jpg'
import GirlCakeFirst from '../../shared/assets/girl-cake-1.jpg'
import GirlCakeSecond from '../../shared/assets/girl-cake-2.jpg'
import happyBirthdayCakeLambetFirst from '../../shared/assets/happy-birthday-cake-lambet-1.jpg'
import happyBirthdayCakeLambetSecond from '../../shared/assets/happy-birthday-cake-lambet-2.jpg'
import CupcakesSecond from '../../shared/assets/cupcakes.jpg'
import christmasBallsFirst from '../../shared/assets/сhristmas-balls-1.jpg'
import christmasBallsSecond from '../../shared/assets/сhristmas-balls-2.jpg'
import christmasBallsThird from '../../shared/assets/сhristmas-balls-3.jpg'
import christmasBallsFourth from '../../shared/assets/сhristmas-balls-4.jpg'

export const workSlideDataList: WorkCardData[] = [
    {
        images: [
            { src: loveCakeFirst, placeholder: 'RUKKH3*IRjMd' },
            { src: loveCakeSecond, placeholder: 'RdJiw]LzTJVY' },
            { src: loveCakeThird, placeholder: 'RJKIMAQ7HCf*' },
            { src: loveCakeFourth, placeholder: 'RCJHjq-;?^4U' },
        ],
        description: 'Love Cake',
    },
    {
        images: [
            { src: christmasBallsSecond, placeholder: 'RKIOd~x@?^-;' },
            { src: christmasBallsThird, placeholder: 'REH_*-Mv.S4T' },
            { src: christmasBallsFirst, placeholder: 'RBHx?4?w.mDO' },
            { src: christmasBallsFourth, placeholder: 'REI=c9?w.98_' },
        ],
        description: 'Шоколадные шарики с новогодними пожеланиями доступны к заказу',
    },
    {
        images: [
            { src: cupcakesCake, placeholder: 'RCF}_.^%0Lbe' },
            { src: medovikCake, placeholder: 'RFJaAk_N-;tS' },
        ],
        description:
            'Порционные десерты (открытый медовик с ягодами и трайфлы Oreo, ваниль/клубника)',
    },
    {
        images: [{ src: flowersCake, placeholder: 'RGGlU}_4D+t8' }],
        description: 'Торт с декором из вафельной бумаги и живыми цветами',
    },
    {
        images: [
            { src: GenderCakeFirst, placeholder: 'RAG*{6Tf0%4U' },
            { src: GenderCakeSecond, placeholder: 'RAIq}poy~oIY' },
        ],
        description: 'Торт на гендер–пати',
    },
    {
        images: [
            { src: GirlCakeSecond, placeholder: 'RDI=ADgj%%4o' },
            { src: GirlCakeFirst, placeholder: 'RLJu4E_2?wo#' },
        ],
        description: 'Торт на день рождения',
    },
    {
        images: [{ src: happyBirthdayCake, placeholder: 'R8Lqa+yZXr4m' }],
        description: 'Торт на день рождения ребёнка',
    },
    {
        images: [{ src: CupcakesSecond, placeholder: 'R8KdF{8_ys^%' }],
        description: 'Капкейки с клубникой',
    },
    {
        images: [
            { src: CupcakesRedVelvet, placeholder: 'R1HVYq~q.9l.' },
            { src: CupcakesRedVelvetFirst, placeholder: 'RDI#fK9E~qR5' },
            { src: CupcakesRedVelvetSecond, placeholder: 'R9Gum?01Iq.8' },
            { src: CupcakesRedVelvetThird, placeholder: 'RBEoxrI-4m-=' },
        ],
        description: 'Трайфлы: красный бархат и сникерс',
    },
    {
        images: [
            { src: happyBirthdayCakeLambetFirst, placeholder: 'RGM7u*%h.AIT' },
            { src: happyBirthdayCakeLambetSecond, placeholder: 'R4H.Tf?wA4~q' },
        ],
        description: 'Торт в стиле ламбет',
    },
    {
        images: [{ src: MinecraftCake, placeholder: 'RSIY%^?d-?RN' }],
        description: 'Шоу–торт бомба «Minecraft»',
    },
    {
        images: [{ src: happyBirthdayDigitCake, placeholder: 'RHLN##~WtURi' }],
        description: 'Торт на четыре годика',
    },

    {
        images: [
            { src: DecorCakeFirst, placeholder: 'RNJa=Y~p?v.7' },
            { src: DecorCakeSecond, placeholder: 'RUJa.M~px]-o' },
        ],
        description: 'Торт с декором из живых цветов',
    },
]
